import React from "react";
import { graphql } from "gatsby";
import { filterOutDocsWithoutSlugs } from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import HomePage from "../templates/home-page";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings {
      title
    }
    homePageSettings: sanityHomePageSettings {
      title
      description
      keywords
      parallaxImages {
        asset {
          url
          _id
        }
        alt
      }
      parallaxColour {
        hex
      }
      services {
        id
        title
        subtitle
        themeColour {
          hex
        }
        thumbnail {
          asset {
            _id
          }
          alt
        }
        slug {
          current
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const homePageSettings = (data || {}).homePageSettings;
  const serviceNodes = homePageSettings.services
    ? homePageSettings.services.filter(filterOutDocsWithoutSlugs)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  if (typeof window === "undefined") return null;

  return (
    <HomePage
      homePageSettings={homePageSettings}
      serviceNodes={serviceNodes}
      title={site.title}
    />
  );
};

export default IndexPage;
